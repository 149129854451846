export const monetizationChildren = [
  {
    title: 'videoStreamAnalyticsSolution.section2.monetization.tab1.title',
    mainImage: '/images/videoStreamAnalyticsSolution/monetization/How many viewers you can reach with ads_.png',
    mobileImage: '/images/videoStreamAnalyticsSolution/monetization/How many viewers you can reach with ads_mobile.png',
    secondImage:
      '/images/videoStreamAnalyticsSolution/monetization/How many viewers you can reach with ads_  chart.png',
    tryFreeUrl: '',
    content: 'videoStreamAnalyticsSolution.section2.monetization.tab1.content',
    tags: [
      'videoStreamAnalyticsSolution.section2.monetization.tab1.tag1',
      'videoStreamAnalyticsSolution.section2.monetization.tab1.tag2'
    ]
  },
  {
    title: 'videoStreamAnalyticsSolution.section2.monetization.tab2.title',
    mainImage: '/images/videoStreamAnalyticsSolution/monetization/Are your ads working_.png',
    secondImage: '/images/videoStreamAnalyticsSolution/monetization/Are your ads working_ chart.png',
    tryFreeUrl: '',
    content: 'videoStreamAnalyticsSolution.section2.monetization.tab2.content',
    tags: ['videoStreamAnalyticsSolution.section2.monetization.tab2.tag1']
  },
  {
    title: 'videoStreamAnalyticsSolution.section2.monetization.tab3.title',
    mainImage: '/images/videoStreamAnalyticsSolution/monetization/How many viewers engaged with your ads_.png',
    secondImage: '/images/videoStreamAnalyticsSolution/monetization/How many viewers engaged with your ads_ chart.png',
    tryFreeUrl: '',
    content: 'videoStreamAnalyticsSolution.section2.monetization.tab3.content',
    tags: ['videoStreamAnalyticsSolution.section2.monetization.tab3.tag1']
  },
  {
    title: 'videoStreamAnalyticsSolution.section2.monetization.tab4.title',
    mainImage: '/images/videoStreamAnalyticsSolution/monetization/How good is your ads performance_.png',
    secondImage: '/images/videoStreamAnalyticsSolution/monetization/How good is your ads performance_ chart.png',
    tryFreeUrl: '',
    content: 'videoStreamAnalyticsSolution.section2.monetization.tab4.content',
    tags: ['videoStreamAnalyticsSolution.section2.monetization.tab4.tag1']
  },
  {
    title: 'videoStreamAnalyticsSolution.section2.monetization.tab5.title',
    mainImage:
      '/images/videoStreamAnalyticsSolution/monetization/Can your system handle large number of requests and ads_.png',
    secondImage:
      '/images/videoStreamAnalyticsSolution/monetization/Can your system handle large number of requests and ads_ chart.png',
    tryFreeUrl: '',
    content: 'videoStreamAnalyticsSolution.section2.monetization.tab5.content',
    tags: ['videoStreamAnalyticsSolution.section2.monetization.tab5.tag1']
  }
]

export const viewersAnalyticsChildren = [
  {
    title: 'videoStreamAnalyticsSolution.section2.viewersAnalytics.tab1.title',
    mainImage: '/images/videoStreamAnalyticsSolution/viewersAnalytics/Where are your viewers_.png',
    secondImage: '/images/videoStreamAnalyticsSolution/viewersAnalytics/Where are your viewers_ chart.png',
    tryFreeUrl: '',
    content: 'videoStreamAnalyticsSolution.section2.viewersAnalytics.tab1.content',
    tags: [
      'videoStreamAnalyticsSolution.section2.viewersAnalytics.tab1.tag1',
      'videoStreamAnalyticsSolution.section2.viewersAnalytics.tab1.tag2'
    ]
  },
  {
    title: 'videoStreamAnalyticsSolution.section2.viewersAnalytics.tab2.title',
    mainImage: '/images/videoStreamAnalyticsSolution/viewersAnalytics/Is your audience engaged enough_.png',
    secondImage: '/images/videoStreamAnalyticsSolution/viewersAnalytics/Is your audience engaged enough_ chart.png',
    tryFreeUrl: '',
    content: 'videoStreamAnalyticsSolution.section2.viewersAnalytics.tab2.content',
    tags: ['videoStreamAnalyticsSolution.section2.viewersAnalytics.tab2.tag1']
  },
  {
    title: 'videoStreamAnalyticsSolution.section2.viewersAnalytics.tab3.title',
    mainImage: '/images/videoStreamAnalyticsSolution/viewersAnalytics/What makes your viewers leave...png',
    secondImage: '/images/videoStreamAnalyticsSolution/viewersAnalytics/What makes your viewers leave.. chart.png',
    tryFreeUrl: '',
    content: 'videoStreamAnalyticsSolution.section2.viewersAnalytics.tab3.content',
    tags: ['videoStreamAnalyticsSolution.section2.viewersAnalytics.tab3.tag1']
  },
  {
    title: 'videoStreamAnalyticsSolution.section2.viewersAnalytics.tab4.title',
    mainImage: '/images/videoStreamAnalyticsSolution/viewersAnalytics/_and stay_.png',
    secondImage: '/images/videoStreamAnalyticsSolution/viewersAnalytics/_and stay_ chart.png',
    tryFreeUrl: '',
    content: 'videoStreamAnalyticsSolution.section2.viewersAnalytics.tab4.content',
    tags: ['videoStreamAnalyticsSolution.section2.viewersAnalytics.tab4.tag1']
  }
]

export const viewersPerformanceChildren = [
  {
    title: 'videoStreamAnalyticsSolution.section2.videoPerformance.tab1.title',
    mainImage:
      '/images/videoStreamAnalyticsSolution/videoPerformance/How satisfied your viewers with your video stream_.png',
    secondImage:
      '/images/videoStreamAnalyticsSolution/videoPerformance/How satisfied your viewers with your video stream_ chart.png',
    tryFreeUrl: '',
    content: 'videoStreamAnalyticsSolution.section2.videoPerformance.tab1.content',
    tags: ['videoStreamAnalyticsSolution.section2.videoPerformance.tab1.tag1']
  },
  {
    title: 'videoStreamAnalyticsSolution.section2.videoPerformance.tab2.title',
    mainImage:
      '/images/videoStreamAnalyticsSolution/videoPerformance/Did the video content start smoothly for your viewers_.png',
    secondImage:
      '/images/videoStreamAnalyticsSolution/videoPerformance/Did the video content start smoothly for your viewers_ chart.png',
    tryFreeUrl: '',
    content: 'videoStreamAnalyticsSolution.section2.videoPerformance.tab2.content',
    tags: [
      'videoStreamAnalyticsSolution.section2.videoPerformance.tab2.tag1',
      'videoStreamAnalyticsSolution.section2.videoPerformance.tab2.tag2'
    ]
  },
  {
    title: 'videoStreamAnalyticsSolution.section2.videoPerformance.tab3.title',
    mainImage:
      '/images/videoStreamAnalyticsSolution/videoPerformance/How good is the quality of your video stream_.png',
    secondImage:
      '/images/videoStreamAnalyticsSolution/videoPerformance/How good is the quality of your video stream_ chart.png',
    tryFreeUrl: '',
    content: 'videoStreamAnalyticsSolution.section2.videoPerformance.tab3.content',
    tags: [
      'videoStreamAnalyticsSolution.section2.videoPerformance.tab3.tag1',
      'videoStreamAnalyticsSolution.section2.videoPerformance.tab3.tag2'
    ]
  }
]

export const videoStreamTabs = [
  {
    title: 'videoStreamAnalyticsSolution.section2.tabs.tab1',
    key: 0,
    imageSrc: '/images/videoStreamAnalyticsSolution/monetization.png',
    children: monetizationChildren
  },
  {
    title: 'videoStreamAnalyticsSolution.section2.tabs.tab2',
    key: 1,
    imageSrc: '/images/videoStreamAnalyticsSolution/viewers_analytics.png',
    children: viewersAnalyticsChildren
  },
  {
    title: 'videoStreamAnalyticsSolution.section2.tabs.tab3',
    key: 2,
    imageSrc: '/images/videoStreamAnalyticsSolution/video_performance_qoE_and_qoS.png',
    children: viewersPerformanceChildren
  }
]

export const TechnicalSpecs = [
  {
    title: 'videoStreamAnalyticsSolution.section8.list1.title',
    functions: [
      {
        title: 'videoStreamAnalyticsSolution.section8.list1.function1.title',
        features: [
          'videoStreamAnalyticsSolution.section8.list1.function1.feature1',
          'videoStreamAnalyticsSolution.section8.list1.function1.feature2',
          'videoStreamAnalyticsSolution.section8.list1.function1.feature3',
          'videoStreamAnalyticsSolution.section8.list1.function1.feature4',
          'videoStreamAnalyticsSolution.section8.list1.function1.feature5',
          'videoStreamAnalyticsSolution.section8.list1.function1.feature6',
          'videoStreamAnalyticsSolution.section8.list1.function1.feature7',
          'videoStreamAnalyticsSolution.section8.list1.function1.feature8',
          'videoStreamAnalyticsSolution.section8.list1.function1.feature9'
        ]
      },
      {
        title: 'videoStreamAnalyticsSolution.section8.list1.function2.title',
        features: [
          'videoStreamAnalyticsSolution.section8.list1.function2.feature1',
          'videoStreamAnalyticsSolution.section8.list1.function2.feature2',
          'videoStreamAnalyticsSolution.section8.list1.function2.feature3',
          'videoStreamAnalyticsSolution.section8.list1.function2.feature4',
          'videoStreamAnalyticsSolution.section8.list1.function2.feature5',
          'videoStreamAnalyticsSolution.section8.list1.function2.feature6',
          'videoStreamAnalyticsSolution.section8.list1.function2.feature7',
          'videoStreamAnalyticsSolution.section8.list1.function2.feature8'
        ]
      },
      {
        title: 'videoStreamAnalyticsSolution.section8.list1.function3.title',
        features: [
          'videoStreamAnalyticsSolution.section8.list1.function3.feature1',
          'videoStreamAnalyticsSolution.section8.list1.function3.feature2',
          'videoStreamAnalyticsSolution.section8.list1.function3.feature3',
          'videoStreamAnalyticsSolution.section8.list1.function3.feature4'
        ]
      },
      {
        title: 'videoStreamAnalyticsSolution.section8.list1.function4.title',
        features: [
          'videoStreamAnalyticsSolution.section8.list1.function4.feature1',
          'videoStreamAnalyticsSolution.section8.list1.function4.feature2',
          'videoStreamAnalyticsSolution.section8.list1.function4.feature3'
        ]
      },
      {
        title: 'videoStreamAnalyticsSolution.section8.list1.function5.title',
        features: [
          'videoStreamAnalyticsSolution.section8.list1.function5.feature1',
          'videoStreamAnalyticsSolution.section8.list1.function5.feature2'
        ]
      },
      {
        title: 'videoStreamAnalyticsSolution.section8.list1.function6.title',
        features: [
          'videoStreamAnalyticsSolution.section8.list1.function6.feature1',
          'videoStreamAnalyticsSolution.section8.list1.function6.feature2'
        ]
      }
    ]
  },
  {
    title: 'videoStreamAnalyticsSolution.section8.list2.title',
    subTitle: 'videoStreamAnalyticsSolution.section8.list2.subTitle',
    functions: [
      {
        title: 'videoStreamAnalyticsSolution.section8.list2.function1.title',
        features: [
          'videoStreamAnalyticsSolution.section8.list2.function1.feature1',
          'videoStreamAnalyticsSolution.section8.list2.function1.feature2',
          'videoStreamAnalyticsSolution.section8.list2.function1.feature3'
        ]
      },
      {
        title: 'videoStreamAnalyticsSolution.section8.list2.function2.title',
        features: [
          'videoStreamAnalyticsSolution.section8.list2.function2.feature1',
          'videoStreamAnalyticsSolution.section8.list2.function2.feature2',
          'videoStreamAnalyticsSolution.section8.list2.function2.feature3'
        ]
      },
      {
        title: 'videoStreamAnalyticsSolution.section8.list2.function3.title',
        features: [
          'videoStreamAnalyticsSolution.section8.list2.function3.feature1',
          'videoStreamAnalyticsSolution.section8.list2.function3.feature2',
          'videoStreamAnalyticsSolution.section8.list2.function3.feature3'
        ]
      },
      {
        title: 'videoStreamAnalyticsSolution.section8.list2.function4.title',
        features: [
          'videoStreamAnalyticsSolution.section8.list2.function4.feature1',
          'videoStreamAnalyticsSolution.section8.list2.function4.feature2',
          'videoStreamAnalyticsSolution.section8.list2.function4.feature3',
          'videoStreamAnalyticsSolution.section8.list2.function4.feature4',
          'videoStreamAnalyticsSolution.section8.list2.function4.feature5',
          'videoStreamAnalyticsSolution.section8.list2.function4.feature6'
        ]
      },
      {
        title: 'videoStreamAnalyticsSolution.section8.list2.function5.title',
        features: [
          'videoStreamAnalyticsSolution.section8.list2.function5.feature1',
          'videoStreamAnalyticsSolution.section8.list2.function5.feature2',
          'videoStreamAnalyticsSolution.section8.list2.function5.feature3',
          'videoStreamAnalyticsSolution.section8.list2.function5.feature4'
        ]
      },
      {
        title: 'videoStreamAnalyticsSolution.section8.list2.function6.title',
        features: [
          'videoStreamAnalyticsSolution.section8.list2.function6.feature1',
          'videoStreamAnalyticsSolution.section8.list2.function6.feature2'
        ]
      }
    ]
  },
  {
    title: 'videoStreamAnalyticsSolution.section8.list3.title',
    functions: [
      {
        title: 'videoStreamAnalyticsSolution.section8.list3.function1.title',
        features: [
          'videoStreamAnalyticsSolution.section8.list3.function1.feature1',
          'videoStreamAnalyticsSolution.section8.list3.function1.feature2',
          'videoStreamAnalyticsSolution.section8.list3.function1.feature3',
          'videoStreamAnalyticsSolution.section8.list3.function1.feature4'
        ]
      },
      {
        title: 'videoStreamAnalyticsSolution.section8.list3.function2.title',
        features: ['videoStreamAnalyticsSolution.section8.list3.function2.feature1']
      },
      {
        title: 'videoStreamAnalyticsSolution.section8.list3.function3.title',
        features: ['videoStreamAnalyticsSolution.section8.list3.function3.feature1']
      }
    ]
  }
]
