import React from 'react'
import { graphql, Link } from 'gatsby'
import { useTranslation, Trans } from 'gatsby-plugin-react-i18next'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Layout from 'layouts/index'
import Seo from 'components/seo'
import ZoomGallery from 'components/gallery/zoom'

import I18nImage from 'components/gallery/i18nImage'
import DisplayPanel from '../../components/panels/displayInfoPanel'
import { videoStreamTabs, TechnicalSpecs } from '/static/data/videoStreamAnalytics.static'
const VideoStreamAnalyticsSolution = () => {
  const { t } = useTranslation()
  const [value, setValue] = React.useState(videoStreamTabs[0].key)

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  return (
    <Layout>
      <React.Fragment>
        <Seo
          seo={{
            metaTitle: t('videoStreamAnalyticsSolution.meta.title'),
            metaDescription: t('videoStreamAnalyticsSolution.meta.description'),
            metaUrl: 'https://www.mlytics.com/video-streaming-analytics-solution',
            shareImage: {
              imageUrl: 'https://www.mlytics.com/wp-content/uploads/2022/06/logo_Mlytics_regular.png',
              imageWidth: 1200,
              imageHeight: 630,
              imageType: 'image/jpeg'
            }
          }}
        />
        <section className="container flex flex-col-reverse lg:flex-row py-24">
          <div className="flex-1 mt-10">
            <div className="px-4">
              <h1
                className="text-primary-600 text-[28px] lg:text-start lg:text-[48px] leading-[1.2] text-center"
                style={{ '& a': { color: '#020202' } }}
              >
                <Trans i18nKey="videoStreamAnalyticsSolution.section1.title">
                  <a className="text-secondary-600"></a>
                </Trans>
              </h1>
              <p className="py-5 text-gray-600">{t('videoStreamAnalyticsSolution.section1.description')}</p>
              <div className="flex flex-row  gap-3 my-1">
                <Link to="/signup/" className="btn btn-bold btn-orange inline-block" style={{ width: 208 }}>
                  {t('button.freeTrial.0')}
                </Link>
                <Link
                  to="/lp/get-a-demo/"
                  className="btn btn-bold btn-ghost btn-orange inline-block"
                  style={{ width: 208 }}
                >
                  {t('videoStreamAnalyticsSolution.section1.btn')}
                </Link>
              </div>
            </div>
          </div>
          <div className="flex justify-center flex-1 lg:px-8">
            <ZoomGallery>
              <I18nImage
                src={'/images/videoStreamAnalyticsSolution/video_streaming_analytics.png'}
                alt={t('videoStreamAnalyticsSolution.section1.title')}
                style={{ height: 'auto', maxWidth: '100%' }}
              />
            </ZoomGallery>
          </div>
        </section>
        <section className=" w-full  mt-10 md:h-[100px] flex justify-center bg-blue-300">
          <Tabs
            value={value}
            onChange={handleChange}
            textColor="primary"
            variant="fullWidth"
            aria-label="primary tabs"
            className=" max-w-[1366px] w-full"
            sx={{
              '& .MuiTab-root.Mui-selected': {
                backgroundColor: '#527B7F'
              },
              '& .MuiTabs-indicator': {
                height: '10px',
                bottom: '-10px',
                background: '#21838D',
                '@media (max-width: 640px)': {
                  height: '6px',
                  bottom: '-6px'
                }
              },
              '& .MuiTab-root': {
                padding: 0
              },
              '& .MuiTabs-scroller': {
                overflow: 'visible !important'
              },
              '&.MuiTabs-root': {
                overflow: 'visible'
              }
            }}
          >
            {videoStreamTabs.map(({ title, key, imageSrc }) => (
              <Tab
                key={key}
                className="p-0"
                value={key}
                sx={{
                  '& .MuiButtonBase-root': {},
                  '& .group-hover:scale-115': {
                    transform: 'scale(1.15)'
                  },
                  '& .group-hover.filter': {
                    filter: 'grayscale(100%)'
                  }
                }}
                label={
                  <div className="w-full md:h-[100px] h-[4rem] relative flex justify-center items-center group">
                    <div
                      className={`${
                        key === 1 ? 'px-3 md:px-0' : ''
                      } text-white font-bold normal-case	absolute md:text-3xl text-sm z-10 text-shadow`}
                      style={{ textShadow: '0px 2px 8px rgba(0, 0, 0, 0.65)' }}
                    >
                      <Trans i18nKey={title}>
                        <div className="md:text-2xl text-sm"></div>
                      </Trans>
                    </div>
                    <img
                      src={imageSrc}
                      alt={title}
                      className={`w-full h-full sm:h-auto transition-transform duration-300 ease-in-out scale-105 group-hover:scale-125 ${
                        value !== key ? 'filter grayscale group-hover:filter-none' : ''
                      }`}
                    />
                  </div>
                }
              />
            ))}
          </Tabs>
        </section>
        <section className="mb-20  py-5 sm:py-20 bg-blue-300/10 relative w-full overflow-hidden h-full flex justify-center">
          {/* 背景網格 */}
          <div className="grid grid-cols-6 grid-rows-1 md:grid-cols-9  absolute  z-[-5] top-0 w-full h-full items-stretch max-w-[1366px] mx-auto ">
            {Array.from({ length: 9 }, (_, index) => (
              <div
                key={`${index}_grids`}
                className={`border border-transparent items-stretch ${
                  index === 0 ? 'border-x-blue-50/20' : 'border-r-blue-50/20'
                }`}
              ></div>
            ))}
          </div>
          <div className="absolute left-0 top-0 mt-[6px] sm:mt-[10px] w-full h-full z-[-3]  min-h-[1000px]">
            {Array.from({ length: 19 }, (_, index) => (
              <div key={index} className=" border border-transparent border-t-blue-50/20 w-full h-40"></div>
            ))}
          </div>
          {/* 背景網格 */}
          <div
            className="container flex flex-col min-h-[600px] md:flex-row top-10 left-0 z-10 w-full"
            // style={{ minWidth: '100%' }}
          >
            <DisplayPanel value={value} />
          </div>
        </section>
        <section className="my-20 px-4 md:px-10">
          <div className="container pb-16 md:pb-32">
            <h2 className="flex text-center text-blue-300 lg:text-[48px] text-[28px] leading-tight">
              {' '}
              {t('videoStreamAnalyticsSolution.section3.headTitle')}
            </h2>
          </div>
          <div className="container flex flex-col-reverse md:flex-row">
            <div className="flex-1 md:px-8 pb-5">
              <h2 className="font-bold text-[28px] md:text-[32px] text-center md:text-start">
                {t('videoStreamAnalyticsSolution.section3.title')}
              </h2>
              <p className="py-5 text-gray-600">{t('videoStreamAnalyticsSolution.section3.description')}</p>
              <Link to="/lp/get-a-demo/" className="btn btn-bold btn-orange btn-ghost border-2 w-full md:w-auto">
                {t('getDemo.title')}
              </Link>
            </div>
            <div className="flex-1 md:px-8 pb-5 md:pb-0">
              <ZoomGallery>
                <I18nImage
                  src={'/images/videoStreamAnalyticsSolution/features/understand_audiences_experience.png'}
                  alt={t('videoStreamAnalyticsSolution.section3.title')}
                  style={{ height: 'auto', maxWidth: '100%' }}
                />
              </ZoomGallery>
            </div>
          </div>
        </section>
        <section className="my-20 px-4 md:px-10">
          <div className="container flex flex-col md:flex-row">
            <div className="flex flex-1 justify-center md:px-8 pb-5 md:pb-0">
              <ZoomGallery>
                <I18nImage
                  src={'/images/videoStreamAnalyticsSolution/features/optimize_your_costs.png'}
                  alt={t('videoStreamAnalyticsSolution.section4.title')}
                  style={{ height: 'auto', maxWidth: '100%' }}
                />
              </ZoomGallery>
            </div>
            <div className="flex-1 md:px-8 pb-5">
              <h2 className="font-bold text-[28px] md:text-[32px]  text-center md:text-start">
                {t('videoStreamAnalyticsSolution.section4.title')}
              </h2>
              <p className="py-5 text-gray-600">
                <Trans i18nKey={'videoStreamAnalyticsSolution.section4.description'}>
                  <Link
                    className="text-secondary-600 underline"
                    to="https://www.mlytics.com/video-stream/instream-switching/"
                  ></Link>
                </Trans>
              </p>
              <Link to="/lp/get-a-demo/" className="btn btn-bold btn-orange btn-ghost border-2 w-full md:w-auto">
                {t('getDemo.title')}
              </Link>
            </div>
          </div>
        </section>
        <section className="my-20 px-4 md:px-10">
          <div className="container flex flex-col-reverse md:flex-row">
            <div className="flex-1 md:px-8">
              <h2 className=" text-gray-800 text-[28px] md:text-[32px]  text-center md:text-start">
                {t('videoStreamAnalyticsSolution.section5.title')}
              </h2>
              <p className="py-5 text-gray-600">
                <Trans i18nKey={'videoStreamAnalyticsSolution.section5.description'}>
                  <Link
                    to="https://www.mlytics.com/video-stream/instream-switching/"
                    className="text-secondary-600 underline"
                  ></Link>
                </Trans>
              </p>
              <Link to="/lp/get-a-demo/" className="btn btn-bold btn-orange btn-ghost border-2 w-full md:w-auto">
                {t('getDemo.title')}
              </Link>
            </div>
            <div className="flex-1 md:px-8 pb-5 md:pb-0">
              <ZoomGallery>
                <I18nImage
                  src={'/images/videoStreamAnalyticsSolution/features/solve_video_streaming_problems.png'}
                  alt={t('videoStreamAnalyticsSolution.section5.title')}
                  style={{ height: 'auto', maxWidth: '100%' }}
                />
              </ZoomGallery>
            </div>
          </div>
        </section>
        <section className="my-20 px-4 md:px-10">
          <div className="container flex flex-col md:flex-row">
            <div className="flex flex-1 justify-center md:px-8 pb-5 md:pb-0">
              <ZoomGallery>
                <I18nImage
                  src={'/images/videoStreamAnalyticsSolution/features/enhance_performance_data.png'}
                  alt={t('videoStreamAnalyticsSolution.section6.imageAlt')}
                  style={{ height: 'auto', maxWidth: '100%' }}
                />
              </ZoomGallery>
            </div>
            <div className="flex-1 md:px-8 ">
              <h2 className=" text-gray-800 text-[28px] md:text-[32px] text-center md:text-start">
                {t('videoStreamAnalyticsSolution.section6.title')}
              </h2>
              <p className="py-5 text-gray-600">
                <Trans i18nKey={'videoStreamAnalyticsSolution.section6.description'}>
                  <Link to="https://www.mlytics.com/features/pulse/" className="text-secondary-600 underline"></Link>
                </Trans>
              </p>
              <Link to="/lp/get-a-demo/" className="btn btn-bold btn-orange btn-ghost border-2 w-full md:w-auto">
                {t('getDemo.title')}
              </Link>
            </div>
          </div>
        </section>
        <section className="bg-gradient-blue  md:px-10 py-16 ">
          <div className="container w-full h-auto ">
            <div className="">
              <div className="text-center text-white text-3xl md:text-[40px] md:px-[10%] leading-tight">
                <Trans i18nKey={'videoStreamAnalyticsSolution.section7.title'}>
                  <a className="font-semibold cursor-text"></a>
                </Trans>
              </div>
            </div>

            <div className="flex flex-col lg:flex-row gap-3 items-center justify-center mt-10">
              <Link to="/signup/" className="btn btn-bold btn-orange inline-block" style={{ width: 208 }}>
                {t('button.freeTrial.0')}
              </Link>
              <Link
                to="/lp/get-a-demo/"
                className="btn btn-bold btn-orange btn-ghost inline-block"
                style={{ width: 208 }}
              >
                {t('videoStreamAnalyticsSolution.section1.btn')}
              </Link>
            </div>
          </div>
        </section>
        <section className="  px-10 py-24">
          <h2 className="font-black text-center text-4xl md:text-5xl text-primary-600">
            {t('videoStreamAnalyticsSolution.section8.title')}
          </h2>
          <div className="container mt-16 md:px-[18px] px-0">
            <div className="rounded-3xl border border-blue-300 overflow-hidden">
              {TechnicalSpecs.map(({ title, subTitle, functions }, index) => (
                <div className="grid grid-cols-4" key={`${index}_listforSpecs`}>
                  <div
                    className={`header ${
                      index === TechnicalSpecs.length - 1 ? '' : 'border-b border-zinc-400'
                    }	 col-span-4 xl:col-span-1 flex-col flex py-5 justify-center xl:items-center text-center text-white  px-5 md:px-10 bg-blue-200`}
                  >
                    <div className="text-xl font-extrabold">{t(title)}</div>
                    {subTitle ? <div className="font-extralight text-xs mt-1 md:mt-2">{t(subTitle)}</div> : null}
                  </div>
                  <div
                    className={`xl:col-span-3 col-span-4 grid gap-5 grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 px-5 md:px-10 py-3 md:py-5 bg-white ${
                      index === TechnicalSpecs.length - 1 ? '' : 'border-b border-blue-300'
                    }	`}
                  >
                    {functions.map(({ title, features }, key) => (
                      <div key={`functions_${key}`}>
                        <div className="font-base font-extrabold">{t(title)}</div>
                        <div className="pl-3 mt-1">
                          {features.map((feature, key) => (
                            <div className="font-light text-gray-600 flex items-center" key={`feature_${key}`}>
                              <div className="block w-[6px] h-[6px] mr-2 rounded-xl bg-gray-600"></div> {t(feature)}
                            </div>
                          ))}
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      </React.Fragment>
    </Layout>
  )
}

export default VideoStreamAnalyticsSolution

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
